<template>
  <div class="ph-table">
    <v-data-table
      v-model="selectedRows"
      :disable-pagination="disablePagination"
      :headers="headers"
      :header-props="headerProps"
      :hide-default-footer="hideFooter"
      :fixed-header="true || fixedHeader"
      :footer-props="footerProps"
      :items="items"
      :loading="loading"
      :server-items-length="serverItemsLength"
      :show-select="selectable"
      :sort-by.sync="sortByField"
      :sort-desc.sync="sortInDescendingOrder"
      :height="height"
      calculate-widths
    >
      <template v-slot:item="{ isSelected, item, headers, select }">
        <slot name="item" :item="item">
          <tr @click="handleRowClick(item)">
            <td
              v-for="(header, headerIdx) in headers"
              :key="`header_${header.value || 'system'}_${headerIdx}`"
            >
              <span v-if="selectable && headerIdx === 0">
                <v-checkbox
                  @change="select"
                  :input-value="isSelected"
                  class="ma-0 pt-0"
                  hide-details
                >
                </v-checkbox>
              </span>
              <span v-else-if="typeof header.value === 'function'">
                <slot :item="item" :header="header">
                  {{ header.value(item) }}
                </slot>
              </span>
              <span v-else>
                <slot :name="`item.${header.value}`" :item="item" :header="header">
                  {{ item[header.value] }}
                </slot>
              </span>
            </td>
          </tr>
        </slot>
      </template>
      <template v-slot:no-data>
        <span class="justify-center">
          {{ noDataText }}
        </span>
      </template>
      <template v-slot:loading>
        <span class="justify-center">
          Loading...
        </span>
      </template>
      <template v-slot:progress>
        <div></div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import tableProperties from '@/mixins/tableProperties';

export default {
  name: 'PhTable',
  mixins: [tableProperties],
  props: {
    disableItemsPerPage: {
      type: Boolean,
      default: true,
    },
    disablePagination: {
      type: Boolean,
      default: true,
    },
    fixedHeader: Boolean,
    selectable: Boolean,
    loading: Boolean,
    noDataText: {
      type: String,
      default: 'No data available',
    },
  },
  computed: {
    headerProps() {
      return {
        sortIcon: 'keyboard_arrow_up',
      };
    },
    footerProps() {
      return {
        disablePagination: true,
        disableItemsPerPage: true,
      };
    },
    hideFooter() {
      return this.disablePagination;
    },
  },
  methods: {
    handleRowClick(item) {
      this.$emit('rowClick', item);
    },
  },
};
</script>

<style lang="scss">
$ph-table-cell-border: 1px solid $border-light;
.ph-table {
  width: 100%;
  .v-data-table,
  .theme--light.v-data-table {
    background-color: transparent;
    thead.v-data-table-header {
      th {
        color: $text-light;
        font-weight: 300;
        font-size: 0.8125rem;
        box-shadow: none;
      }
      tr:last-child {
        th {
          border-bottom: none;
        }
      }
    }
    tbody {
      tr {
        &:hover:not(.v-data-table__expanded__content),
        &.v-data-table__expanded__content {
          background: initial;
        }
        td {
          // padding-top: 13px;
          // padding-bottom: 13px;
          position: relative;
          color: #454b60;
          font-size: 1rem;
          font-weight: 500;
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: $background-lightest;
            border-top: $ph-table-cell-border;
            border-bottom: $ph-table-cell-border;
            transition: border-color $transition-duration ease-in;
          }
          &:first-child {
            &::before {
              border-left: $ph-table-cell-border;
              border-top-left-radius: 12px;
              border-bottom-left-radius: 12px;
            }
          }
          &:last-child {
            &::before {
              border-right: $ph-table-cell-border;
              border-top-right-radius: 12px;
              border-bottom-right-radius: 12px;
            }
          }
          span {
            position: relative;
            min-height: 70px;
            align-items: center;
            display: flex;
          }
        }
        &:hover {
          td {
            &::before {
              border-color: $success;
            }
          }
        }
      }
      tr:not(:last-child) {
        td:not(.v-data-table__mobile-row) {
          // border-bottom: 1px solid $border-light;
          border-bottom: 15px solid transparent;
        }
      }
    }
    &.v-data-table--fixed-header {
      thead.v-data-table-header {
        th {
          background: transparent;
        }
      }
    }
  }
}
</style>
