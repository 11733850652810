<template>
  <div :class="classes" class="page-filter-bar__filter">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageFilter',
  props: {
    type: String,
    wide: Boolean,
  },
  computed: {
    classes() {
      const checkTypeIsValid = type => {
        const validTypes = ['search', 'date-range'];
        return typeof type === 'string' && type.length && validTypes.includes(type);
      };
      return {
        [`page-filter-bar__filter--${this.type}`]: checkTypeIsValid(this.type),
        'page-filter-bar__filter--wide': this.wide,
      };
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/base/index';

.page-filter-bar__filter {
  & > .v-input {
    padding-top: 0px;
  }
  @include bp-up(md) {
    &:nth-child(1n + 2) {
      margin-left: 35px;
    }
    &--wide {
      max-width: inherit;
      width: 100%;
    }
    &--search {
      max-width: 360px;
    }
    &--date-range {
      max-width: 360px;
    }
  }
}
</style>
