<template>
  <div class="select-filter">
    <span v-if="label" @click="focusOnSelect" class="select-filter__label"> {{ label }}: </span>
    <v-select
      @input="handleInput"
      :items="items"
      :placeholder="placeholder"
      :value="value"
      :multiple="isMultiple"
      append-icon="keyboard_arrow_down"
      class="select-filter__select"
      :class="{ 'select-filter__select--full-width': fullWidth }"
      color="success"
      flat
      hide-details
      outlined
      rounded
      solo
      ref="select"
    />
  </div>
</template>

<script>
export default {
  name: 'SelectFilter',
  props: {
    fullWidth: Boolean,
    items: Array,
    label: String,
    placeholder: String,
    value: [String, Number, Object, Array],
  },
  computed: {
    isMultiple() {
      return Array.isArray(this.value);
    },
  },
  methods: {
    handleInput(val) {
      this.$emit('input', val);
    },
    focusOnSelect() {
      if (this.$refs.select && typeof this.$refs.select.focus === 'function') {
        this.$refs.select.focus();
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/base/index';
.select-filter {
  display: inline-flex;
  align-items: center;
  & > .select-filter__select {
    &.v-input {
      &.theme--light {
        .v-input__control {
          .v-input__slot {
            background: $background-lightest;
          }
        }
      }
      &:not(.v-input--is-focused) {
        &.theme--light {
          .v-input__control {
            .v-input__slot {
              fieldset {
                border-color: $background-lightest;
              }
            }
          }
        }
      }
      &:not(.select-filter__select--full-width) {
        @include bp-up(md) {
          max-width: 165px;
        }
      }
      &.select-filter__select--full-width {
        @include bp-up(md) {
          max-width: 320px;
        }
      }
    }
  }
}
.select-filter__label {
  color: $text-light;
  font-size: 0.8rem;
  letter-spacing: 0.666666px;
  line-height: 1.166;
  margin-right: 10px;
  cursor: pointer;
}
</style>
