var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-filter-bar__group",class:{
    'page-filter-bar__group--grow': _vm.grow,
    'page-filter-bar__group--row': _vm.row,
    'page-filter-bar__group--align-center': _vm.align === 'center',
    'page-filter-bar__group--align-end': _vm.align === 'end',
    'page-filter-bar__group--justify-between': _vm.justify === 'between',
    'page-filter-bar__group--justify-end': _vm.justify === 'end',
    'page-filter-bar__group--has-button': _vm.hasButton,
  }},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }