<template>
  <h1 :style="`--text-transform: ${transform}`" class="page-title">
    <slot>{{ content }}</slot>
  </h1>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    content: String,
    transform: {
      type: String,
      default: 'none',
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  color: $text-semi-darkest;
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: 0.608696px;
  text-transform: var(--text-transform);
}
</style>
