<template>
  <div
    :class="{
      'page-filter-bar__group--grow': grow,
      'page-filter-bar__group--row': row,
      'page-filter-bar__group--align-center': align === 'center',
      'page-filter-bar__group--align-end': align === 'end',
      'page-filter-bar__group--justify-between': justify === 'between',
      'page-filter-bar__group--justify-end': justify === 'end',
      'page-filter-bar__group--has-button': hasButton,
    }"
    class="page-filter-bar__group"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageFilterGroup',
  props: {
    align: String,
    grow: Boolean,
    hasButton: Boolean,
    justify: String,
    row: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/base/index';

.page-filter-bar__group {
  display: flex;
  flex-direction: column;
  @include bp-up(md) {
    flex-direction: row;
    align-items: center;
    &:nth-child(1n + 2) {
      margin-left: 35px;
    }
  }
  &--grow {
    flex-grow: 1;
  }
  &--row {
    flex-direction: row;
    & .page-filter-bar__group--justify-between,
    .page-filter-bar__group--justify-end {
      flex-grow: 1;
    }
  }
  &--align-center {
    align-items: center;
  }
  &--align-end {
    align-items: flex-end;
  }
  &--justify-between {
    justify-content: space-between;
  }
  &--justify-end {
    justify-content: flex-end;
  }
}
</style>
