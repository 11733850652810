<template>
  <v-text-field
    v-model="debouncedValue"
    :loading="loading"
    :placeholder="placeholder"
    append-icon="search"
    color="success"
    class="search-text-field"
    hide-details
    outlined
    rounded
    solo
  >
  </v-text-field>
</template>

<script>
import debounceInput from '@/mixins/debounceInput';

export default {
  name: 'SearchTextField',
  mixins: [debounceInput],
  props: {
    loading: Boolean,
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
};
</script>

<style lang="scss">
.search-text-field {
  &.v-text-field {
    &--solo {
      &:not(.v-text-field--solo-flat) {
        & > .v-input__control {
          & > .v-input__slot {
            box-shadow: none;
            padding-right: 16px;
            padding-left: 20px;
          }
        }
      }
    }
    &.theme--light {
      &:not(.v-input--is-focused) {
        & > .v-input__control {
          & > .v-input__slot {
            background: $background-lightest;
            & > fieldset {
              border-color: $background-lightest;
            }
          }
        }
      }
    }
  }
}
</style>
