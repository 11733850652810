<template>
  <v-container class="page-filter-bar-wrapper" :px-0="!paddingX" :py-0="!paddingY">
    <div class="page-filter-bar">
      <div class="page-filter-bar__filter-row">
        <slot></slot>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PageFilterBar',
  props: {
    paddingX: Boolean,
    paddingY: Boolean,
  },
};
</script>

<style lang="scss">
@import '~@/scss/base/index';
.page-filter-bar-wrapper {
  width: 100%;
}
.page-filter-bar {
  display: flex;
  flex-direction: column;
  @include bp-up(md) {
    flex-direction: row;
  }
}
.page-filter-bar__filter-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}
</style>
